var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_vm._l((_vm.futureTour),function(upComing,index){return _c('b-col',{key:index,staticClass:"upEvents",attrs:{"lg":"4","sm":"4"}},[_c('div',{staticClass:"box"},[_c('b-card',{attrs:{"tag":"article"}},[_c('b-link',{staticClass:"noHover",attrs:{"to":{
            name: 'tournament',
            query: { id: _vm.seasonCode, code: upComing.code, tour: _vm.tour },
          }}},[_c('b-card-text',[(upComing.sponsor_logo !== '')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":upComing.sponsor_logo}})],1):(_vm.tour === 'aus')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/logos/2024/107Q/107Q.jpg'}})],1):(_vm.tour === 'snr')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'all')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'trn')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'ppc')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'}})],1):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(upComing.prix_dates)+" ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(upComing.full_name)+" ")]),_c('p',{staticClass:"course"},[_vm._v(" "+_vm._s(upComing.course)+" ")])])],1),(upComing.reports !== '')?_c('div',[_c('b-row',{staticClass:"reportsSection"},[_c('b-col',{staticClass:"noPadRight",attrs:{"lg":"3"}},[(_vm.filterEntry.length && _vm.filterEntry !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"href":"#","role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterEntry.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterEntry.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardOne()}}},[_vm._v(" Entries "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: upComing.reports.reports_entry.report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour: _vm.tour,
                      title: upComing.reports.reports_entry.report_title
                    }
                  }},on:{"click":function($event){return _vm.CardOne()}}},[_vm._v(" Entries "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1)],1),(_vm.filterDraw.length && _vm.filterDraw !== 'none')?_c('b-col',{staticClass:"noPadRight smallnotpadLeft",attrs:{"lg":"3"}},[(_vm.filterDraw.length && _vm.filterDraw !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"href":"#","role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterDraw.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour: _vm.tour,
                      title: _vm.filterDraw.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardOne()}}},[_vm._v(" Draw "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e(),(_vm.filterScore.length && _vm.filterScore !== 'none')?_c('b-col',{staticClass:"noPadRight",attrs:{"lg":"3"}},[(_vm.filterScore.length && _vm.filterScore !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterScore.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterScore.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardOne()}}},[_vm._v(" Scores "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e(),(_vm.filterFinal.length && _vm.filterFinal !== 'none')?_c('b-col',{staticClass:"noPadLeft smallnotpadLeft",attrs:{"lg":"3"}},[(_vm.filterFinal.length && _vm.filterFinal !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterFinal.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterFinal.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardOne()}}},[_vm._v(" Results "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e()],1)],1):_vm._e()],1)],1)])}),_vm._l((_vm.futureTourtwo),function(upComing,index){return _c('b-col',{key:index,staticClass:"upEvents",attrs:{"lg":"4","sm":"4"}},[_c('div',{staticClass:"box"},[_c('b-card',{attrs:{"tag":"article"}},[_c('b-link',{staticClass:"noHover",attrs:{"to":{
            name: 'tournament',
            query: { id: _vm.seasonCode, code: upComing.code, tour: _vm.tour },
          }}},[_c('b-card-text',[(upComing.sponsor_logo !== '')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":upComing.sponsor_logo}})],1):(_vm.tour === 'aus')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/logos/2024/107Q/107Q.jpg'}})],1):(_vm.tour === 'snr')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'all')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'trn')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'ppc')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'}})],1):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(upComing.prix_dates)+" ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(upComing.full_name)+" ")]),_c('p',{staticClass:"course"},[_vm._v(" "+_vm._s(upComing.course)+" ")])])],1),(upComing.reports !== '')?_c('div',[_c('b-row',{staticClass:"reportsSection"},[_c('b-col',{staticClass:"noPadRight",attrs:{"lg":"3"}},[(_vm.filterEntryTwo.length && _vm.filterEntryTwo !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"href":"#","role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterEntryTwo.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterEntryTwo.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardTwo()}}},[_vm._v(" Entries "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: upComing.reports.reports_entry.report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour: _vm.tour,
                      title: upComing.reports.reports_entry.report_title
                    }
                  }},on:{"click":function($event){return _vm.CardTwo()}}},[_vm._v(" Entries "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1)],1),(_vm.filterDrawTwo.length && _vm.filterDrawTwo !== 'none')?_c('b-col',{staticClass:"noPadRight smallnotpadLeft",attrs:{"lg":"3"}},[(_vm.filterDrawTwo.length && _vm.filterDrawTwo !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"href":"#","role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterDrawTwo.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour: _vm.tour,
                      title: _vm.filterDrawTwo.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardTwo()}}},[_vm._v(" Draw "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e(),(_vm.filterScoreTwo.length && _vm.filterScoreTwo !== 'none')?_c('b-col',{staticClass:"noPadRight",attrs:{"lg":"3"}},[(_vm.filterScoreTwo.length && _vm.filterScoreTwo !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterScoreTwo.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterScoreTwo.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardTwo()}}},[_vm._v(" Scores "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e(),(_vm.filterFinalTwo.length && _vm.filterFinalTwo !== 'none')?_c('b-col',{staticClass:"noPadLeft smallnotpadLeft",attrs:{"lg":"3"}},[(_vm.filterFinalTwo.length && _vm.filterFinalTwo !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterFinalTwo.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterFinalTwo.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardTwo()}}},[_vm._v(" Results "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e()],1)],1):_vm._e()],1)],1)])}),_vm._l((_vm.futureTourthree),function(upComing,index){return _c('b-col',{key:index,staticClass:"upEvents",attrs:{"lg":"4","sm":"4"}},[_c('div',{staticClass:"box"},[_c('b-card',{attrs:{"tag":"article"}},[_c('b-link',{staticClass:"noHover",attrs:{"to":{
            name: 'tournament',
            query: { id: _vm.seasonCode, code: upComing.code, tour: _vm.tour },
          }}},[_c('b-card-text',[(upComing.sponsor_logo !== '')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":upComing.sponsor_logo}})],1):(_vm.tour === 'aus')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/logos/2024/107Q/107Q.jpg'}})],1):(_vm.tour === 'snr')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'all')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'trn')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'}})],1):(_vm.tour === 'ppc')?_c('span',[_c('b-img',{staticClass:"sponLogo",attrs:{"src":_vm.config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'}})],1):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(upComing.prix_dates)+" ")]),_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(upComing.full_name)+" ")]),_c('p',{staticClass:"course"},[_vm._v(" "+_vm._s(upComing.course)+" ")])])],1),(upComing.reports !== '')?_c('div',[_c('b-row',{staticClass:"reportsSection"},[_c('b-col',{staticClass:"noPadRight",attrs:{"lg":"3"}},[(_vm.filterEntryThree.length && _vm.filterEntryThree !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"href":"#","role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterEntryThree.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterEntryThree.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardThree()}}},[_vm._v(" Entries "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: upComing.reports.reports_entry.report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour: _vm.tour,
                      title: upComing.reports.reports_entry.report_title
                    }
                  }},on:{"click":function($event){return _vm.CardThree()}}},[_vm._v(" Entries "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1)],1),(_vm.filterDrawThree.length && _vm.filterDrawThree !== 'none')?_c('b-col',{staticClass:"noPadRight smallnotpadLeft",attrs:{"lg":"3"}},[(_vm.filterDrawThree.length && _vm.filterDrawThree !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"href":"#","role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterDrawThree.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour: _vm.tour,
                      title: _vm.filterDrawThree.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardThree()}}},[_vm._v(" Draw "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e(),(_vm.filterScoreThree.length && _vm.filterScoreThree !== 'none')?_c('b-col',{staticClass:"noPadRight",attrs:{"lg":"3"}},[(_vm.filterScoreThree.length && _vm.filterScoreThree !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterScoreThree.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterScoreThree.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardThree()}}},[_vm._v(" Scores "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e(),(_vm.filterFinalThree.length && _vm.filterFinalThree !== 'none')?_c('b-col',{staticClass:"noPadLeft smallnotpadLeft",attrs:{"lg":"3"}},[(_vm.filterFinalThree.length && _vm.filterFinalThree !== 'none')?_c('b-nav-item',{staticClass:"btnColor",attrs:{"role":"presentation","to":{
                    name: 'reports',
                    query: {
                      url: _vm.filterFinalThree.slice(-1)[0].report_url,
                      id: _vm.seasonCode,
                      code: upComing.code,
                      tour:_vm.tour,
                      title: _vm.filterFinalThree.slice(-1)[0].report_title
                    }
                  }},on:{"click":function($event){return _vm.CardThree()}}},[_vm._v(" Results "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'angle-right']}})],1):_vm._e()],1):_vm._e()],1)],1):_vm._e()],1)],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }