import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons"; 
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
// import { faArrowRightLong } from "@fortawesome/free-brands-svg-icons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import lodash from "lodash";
import $Scriptjs from 'scriptjs';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialSharing from "vue-social-sharing";
import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";
import VueLodash from "vue-lodash";

library.add(
  faFacebook,
  faInstagramSquare,
  faInstagram,
  faTwitter,
  faYoutube,
  faAngleRight,
  faSearch,
  faPlayCircle,
  faArrowDown,
  faArrowUp,
  // faArrowRightLong
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSocialSharing);
Vue.component('gmap-cluster', VueGoogleMaps.Cluster);
Vue.use(VueAnalytics, {
  id: 'UA-11612544-2',
  router
});
Vue.use(VueGtag, {
  config: { id: "UA-11612544-2" },
  includes: [
    { id: 'UA-11612544-2' }
  ]
}, router);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAmSST1dlSLz6jZ7KhVZ2ZQSwa8l-V3b-U',
    libraries: "places",
    installComponents: true
  }
});

new Vue({
  router,
  store,
  axios,
  JQuery,
  lodash,
  $Scriptjs,
  render: h => h(App)
}).$mount("#app");
