<template>
  <b-row>
    <b-col v-for="(upComing, index) in futureTour" :key="index" lg="4" sm="4" class="upEvents">
        <div class='box'>
          <b-card tag="article">
            <b-link class="noHover" :to="{
              name: 'tournament',
              query: { id: seasonCode, code: upComing.code, tour: tour },
            }">
              <b-card-text>
                <span v-if="upComing.sponsor_logo !== ''">
                  <b-img class="sponLogo" :src="upComing.sponsor_logo"></b-img>
                </span>
                <span v-else-if="tour === 'aus'">
                  <b-img
                    class="sponLogo"
                    :src="'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/logos/2024/107Q/107Q.jpg'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'snr'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'all'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'trn'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'ppc'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'"
                  ></b-img>
                </span>
                <p class="date">
                  {{ upComing.prix_dates }}
                </p>
                <p class="name">
                  {{ upComing.full_name }}
                </p>
                <p class="course">
                  {{ upComing.course }}
                </p>
              </b-card-text>
            </b-link>
            <div v-if="upComing.reports !== ''">
              <b-row class="reportsSection">
                <b-col lg="3" class="noPadRight">
                  <b-nav-item
                    @click="CardOne()"
                    class="btnColor"
                    v-if="filterEntry.length && filterEntry !== 'none'"
                    href="#"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterEntry.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterEntry.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                  <b-nav-item
                   @click="CardOne()"
                    class="btnColor"
                    v-else
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: upComing.reports.reports_entry.report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: tour,
                        title: upComing.reports.reports_entry.report_title
                      }
                    }"
                    >
                      Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadRight smallnotpadLeft" v-if="filterDraw.length && filterDraw !== 'none'">
                  <b-nav-item
                    @click="CardOne()"
                    class="btnColor"
                    v-if="filterDraw.length && filterDraw !== 'none'"
                    href="#"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterDraw.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: tour,
                        title: filterDraw.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Draw <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadRight"  v-if="filterScore.length && filterScore !== 'none'">
                  <b-nav-item
                    @click="CardOne()"
                    class="btnColor"
                    v-if="filterScore.length && filterScore !== 'none'"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterScore.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterScore.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Scores <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadLeft smallnotpadLeft" v-if="filterFinal.length && filterFinal !== 'none'">
                  <!-- <b-nav-item
                    class="btnColor"
                    >
                      Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item> -->
                  <b-nav-item
                    @click="CardOne()"
                    class="btnColor"
                    v-if="filterFinal.length && filterFinal !== 'none'"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterFinal.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterFinal.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </div>
    </b-col>
    <b-col v-for="(upComing, index) in futureTourtwo" :key="index" lg="4" sm="4" class="upEvents">
        <div class='box'>
          <b-card tag="article">
            <b-link class="noHover" :to="{
              name: 'tournament',
              query: { id: seasonCode, code: upComing.code, tour: tour },
            }">
              <b-card-text>
                <span v-if="upComing.sponsor_logo !== ''">
                  <b-img class="sponLogo" :src="upComing.sponsor_logo"></b-img>
                </span>
                <span v-else-if="tour === 'aus'">
                  <b-img
                    class="sponLogo"
                    :src="'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/logos/2024/107Q/107Q.jpg'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'snr'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'all'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'trn'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'ppc'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'"
                  ></b-img>
                </span>
                <p class="date">
                  {{ upComing.prix_dates }}
                </p>
                <p class="name">
                  {{ upComing.full_name }}
                </p>
                <p class="course">
                  {{ upComing.course }}
                </p>
              </b-card-text>
            </b-link>
            <div v-if="upComing.reports !== ''">
              <b-row class="reportsSection">
                <b-col lg="3" class="noPadRight">
                  <b-nav-item
                    @click="CardTwo()"
                    class="btnColor"
                    v-if="filterEntryTwo.length && filterEntryTwo !== 'none'"
                    href="#"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterEntryTwo.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterEntryTwo.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                  <b-nav-item
                    @click="CardTwo()"
                    class="btnColor"
                    v-else
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: upComing.reports.reports_entry.report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: tour,
                        title: upComing.reports.reports_entry.report_title
                      }
                    }"
                    >
                      Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadRight smallnotpadLeft"  v-if="filterDrawTwo.length && filterDrawTwo !== 'none'">
                  <!-- <b-nav-item
                    class="btnColor"
                    >
                      Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item> -->
                  <b-nav-item
                    @click="CardTwo()"
                    class="btnColor"
                    v-if="filterDrawTwo.length && filterDrawTwo !== 'none'"
                    href="#"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterDrawTwo.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: tour,
                        title: filterDrawTwo.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Draw <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadRight" v-if="filterScoreTwo.length && filterScoreTwo !== 'none'">
                  <b-nav-item
                    @click="CardTwo()"
                    class="btnColor"
                    v-if="filterScoreTwo.length && filterScoreTwo !== 'none'"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterScoreTwo.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterScoreTwo.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Scores <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadLeft smallnotpadLeft" v-if="filterFinalTwo.length && filterFinalTwo !== 'none'">
                  <b-nav-item
                    @click="CardTwo()"
                    class="btnColor"
                    v-if="filterFinalTwo.length && filterFinalTwo !== 'none'"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterFinalTwo.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterFinalTwo.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </div>
    </b-col>
    <b-col v-for="(upComing, index) in futureTourthree" :key="index" lg="4" sm="4" class="upEvents">
        <div class='box'>
          <b-card tag="article">
            <b-link class="noHover" :to="{
              name: 'tournament',
              query: { id: seasonCode, code: upComing.code, tour: tour },
            }">
              <b-card-text>
                <span v-if="upComing.sponsor_logo !== ''">
                  <b-img class="sponLogo" :src="upComing.sponsor_logo"></b-img>
                </span>
                <span v-else-if="tour === 'aus'">
                  <b-img
                    class="sponLogo"
                    :src="'https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pgaaus/logos/2024/107Q/107Q.jpg'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'snr'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'all'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'trn'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'ppc'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'"
                  ></b-img>
                </span>
                <p class="date">
                  {{ upComing.prix_dates }}
                </p>
                <p class="name">
                  {{ upComing.full_name }}
                </p>
                <p class="course">
                  {{ upComing.course }}
                </p>
              </b-card-text>
            </b-link>
            <div v-if="upComing.reports !== ''">
              <b-row class="reportsSection">
                <b-col lg="3" class="noPadRight">
                  <b-nav-item
                    @click="CardThree()"
                    class="btnColor"
                    v-if="filterEntryThree.length && filterEntryThree !== 'none'"
                    href="#"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterEntryThree.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterEntryThree.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                  <b-nav-item
                    @click="CardThree()"
                    class="btnColor"
                    v-else
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: upComing.reports.reports_entry.report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: tour,
                        title: upComing.reports.reports_entry.report_title
                      }
                    }"
                    >
                      Entries <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadRight smallnotpadLeft" v-if="filterDrawThree.length && filterDrawThree !== 'none'">
                  <b-nav-item
                    @click="CardThree()"
                    class="btnColor"
                    v-if="filterDrawThree.length && filterDrawThree !== 'none'"
                    href="#"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterDrawThree.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: tour,
                        title: filterDrawThree.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Draw <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadRight" v-if="filterScoreThree.length && filterScoreThree !== 'none'">
                  <b-nav-item
                    @click="CardThree()"
                    class="btnColor"
                    v-if="filterScoreThree.length && filterScoreThree !== 'none'"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterScoreThree.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterScoreThree.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Scores <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
                <b-col lg="3" class="noPadLeft smallnotpadLeft" v-if="filterFinalThree.length && filterFinalThree !== 'none'">
                  <b-nav-item
                    @click="CardThree()"
                    class="btnColor"
                    v-if="filterFinalThree.length && filterFinalThree !== 'none'"
                    role="presentation"
                    :to="{
                      name: 'reports',
                      query: {
                        url: filterFinalThree.slice(-1)[0].report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour:tour,
                        title: filterFinalThree.slice(-1)[0].report_title
                      }
                    }"
                    >
                      Results  <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
                  </b-nav-item>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "upComingTours",
  data() {
    return {
      Primary_color: process.env.VUE_APP_PRIMARY_COLOUR,
      fullNameOne: '',
      fullNameTwo: '',
      fullNameThree: ''
    }
  },
  props: ["futureTour", "futureTourtwo", "futureTourthree", 'tour', 'config', 'seasonCode'],
  methods: {
    proAm: function(){
      if (this.$route.query.tour === 'all')
      return this.tourinfo.class_group.toLowerCase()
      else
      return this.$route.query.tour
    },
    CardOne: function() {
      this.fullNameOne = this.futureTour[0].full_name
      localStorage.setItem("UpcomimgName", this.fullNameOne);
    },
    CardTwo: function() {
      this.fullNameTwo = this.futureTourtwo[0].full_name
      localStorage.setItem("UpcomimgName", this.fullNameTwo);
    },
    CardThree: function() {
      this.fullNameThree = this.futureTourthree[0].full_name 
      localStorage.setItem("UpcomimgName", this.fullNameThree);
    }
  },
  computed:{
    extraCol(needed){
      var array = this.futureTour.length
      var total = 3
      needed = total - array
      return needed
    },
    rPlayed: function() {
      return this.futureTour[0].rounds_played;
    },
    rPlayedTwo: function() {
      return this.futureTourtwo[0].rounds_played;
    },
    rPlayedThree: function() {
      return this.futureTourthree[0].rounds_played;
    },
    // CardOne
    filterEntry: function(entry) {
      if (Array.isArray(this.futureTour[0].reports.reports_entry))
        entry = this.futureTour[0].reports.reports_entry.filter(
          futureTour => !futureTour.report_title.indexOf("Tour")
        );
      else if (this.futureTour[0].reports.length)
        entry = this.futureTour[0].reports.reports_entry.report_title;
      else entry = "none";
      return entry;
    },
    filterDraw: function(draw) {
      if (Array.isArray(this.futureTour[0].reports.reports_entry))
        if (this.rPlayed == "0") {
          console.log('193')
          draw = this.futureTour[0].reports.reports_entry.filter(
            futureTour => !futureTour.report_title.indexOf("Round " + "1" + " Draw")
          );
        } else {
          draw = this.futureTour[0].reports.reports_entry.filter(
            futureTour =>
              !futureTour.report_title.indexOf("Round " + this.rPlayed + " Draw")
          );
        }
      else draw = "none";
      return draw;
    },
    filterScore: function(score) {
      if (Array.isArray(this.futureTour[0].reports.reports_entry))
        if (this.rPlayedTwo == "0") {
          score = this.futureTour[0].reports.reports_entry.filter(
            futureTour =>
              !futureTour.report_title.indexOf("Round " + "1" + " Scores Only")
          );
        } else if (this.rPlayed == "1") {
          score = this.futureTour[0].reports.reports_entry.filter(
            futureTour =>
              !futureTour.report_title.indexOf(
                "Round " + this.rPlayed + " Scores Only"
              )
          );
        } else {
          score = this.futureTour[0].reports.reports_entry.filter(
            futureTour =>
              !futureTour.report_title.indexOf(
                "Round " + this.rPlayed + " Scoreboard"
              )
          );
        }
      else score = "none";
      // console.log("score")
      // console.log(score)
      return score;
    },
    filterFinal: function(final) {
      if (Array.isArray(this.futureTour[0].reports.reports_entry))
        final = this.futureTour[0].reports.reports_entry.filter(
          futureTour => !futureTour.report_title.indexOf("Final")
        );
      else final = "none";
      return final;
    },
    // card Two
    filterEntryTwo: function(entry) {
      if (Array.isArray(this.futureTourtwo[0].reports.reports_entry))
        entry = this.futureTourtwo[0].reports.reports_entry.filter(
          futureTourtwo => !futureTourtwo.report_title.indexOf("Tour")
        );
      else if (this.futureTourtwo[0].reports.length)
        entry = this.futureTourtwo[0].reports.reports_entry.report_title;
      else entry = "none";
      return entry;
    },
    filterDrawTwo: function(draw) {
      if (Array.isArray(this.futureTourtwo[0].reports.reports_entry))
        if (this.rPlayedTwo == "0") {
          draw = this.futureTourtwo[0].reports.reports_entry.filter(
            futureTourtwo => !futureTourtwo.report_title.indexOf("Round " + "1" + " Draw")
          );
        } else {
          draw = this.futureTourtwo[0].reports.reports_entry.filter(
            futureTourtwo =>
              !futureTourtwo.report_title.indexOf("Round " + this.rPlayedTwo + " Draw")
          );
        }
      else draw = "none";
      return draw;
    },
    filterScoreTwo: function(score) {
      if (Array.isArray(this.futureTourtwo[0].reports.reports_entry))
        if (this.rPlayedTwo == "0") {
          score = this.futureTourtwo[0].reports.reports_entry.filter(
            futureTourtwo =>
              !futureTourtwo.report_title.indexOf("Round " + "1" + " Scores Only")
          );
        } else if (this.rPlayedTwo == "1") {
          score = this.futureTourtwo[0].reports.reports_entry.filter(
            futureTourtwo =>
              !futureTourtwo.report_title.indexOf(
                "Round " + this.rPlayedTwo + " Scores Only"
              )
          );
        } else {
          score = this.futureTourtwo[0].reports.reports_entry.filter(
            futureTourtwo =>
              !futureTourtwo.report_title.indexOf(
                "Round " + this.rPlayedTwo + " Scoreboard"
              )
          );
        }
      else score = "none";
      // console.log("score")
      // console.log(score)
      return score;
    },
    filterFinalTwo: function(final) {
      if (Array.isArray(this.futureTourtwo[0].reports.reports_entry))
        final = this.futureTourtwo[0].reports.reports_entry.filter(
          futureTourtwo => !futureTourtwo.report_title.indexOf("Final")
        );
      else final = "none";
      return final;
    },
    // card Three
    filterEntryThree: function(entry) {
      if (Array.isArray(this.futureTourthree[0].reports.reports_entry))
        entry = this.futureTourthree[0].reports.reports_entry.filter(
          futureTourthree => !futureTourthree.report_title.indexOf("Tour")
        );
      else if (this.futureTourthree[0].reports.length)
        entry = this.futureTourthree[0].reports.reports_entry.report_title;
      else entry = "none";
      return entry;
    },
    filterDrawThree: function(draw) {
      if (Array.isArray(this.futureTourthree[0].reports.reports_entry))
        if (this.rPlayedThree == "0") {
          draw = this.futureTourthree[0].reports.reports_entry.filter(
            futureTourthree => !futureTourthree.report_title.indexOf("Round " + "1" + " Draw")
          );
        } else {
          draw = this.futureTourthree[0].reports.reports_entry.filter(
            futureTourthree =>
              !futureTourthree.report_title.indexOf("Round " + this.rPlayedThree + " Draw")
          );
        }
      else draw = "none";
      return draw;
    },
    filterScoreThree: function(score) {
      if (Array.isArray(this.futureTourthree[0].reports.reports_entry))
        if (this.rPlayedThree == "0") {
          score = this.futureTourthree[0].reports.reports_entry.filter(
            futureTourthree =>
              !futureTourthree.report_title.indexOf("Round " + "1" + " Scores Only")
          );
        } else if (this.rPlayedThree == "1") {
          score = this.futureTourthree[0].reports.reports_entry.filter(
            futureTourthree =>
              !futureTourthree.report_title.indexOf(
                "Round " + this.rPlayedThree + " Scores Only"
              )
          );
        } else {
          score = this.futureTourthree[0].reports.reports_entry.filter(
            futureTourthree =>
              !futureTourthree.report_title.indexOf(
                "Round " + this.rPlayedThree + " Scoreboard"
              )
          );
        }
      else score = "none";
      // console.log("score")
      // console.log(score)
      return score;
    },
    filterFinalThree: function(final) {
      if (Array.isArray(this.futureTourthree[0].reports.reports_entry))
        final = this.futureTourthree[0].reports.reports_entry.filter(
          futureTourthree => !futureTourthree.report_title.indexOf("Final")
        );
      else final = "none";
      return final;
    }
  },
  mounted() {
    // this.fullNameOne = this.futureTour[0].full_name
    // this.fullNameTwo = this.futureTourtwo[0].full_name
    // this.fullNameThree = this.futureTourthree[0].full_name 
    // localStorage.setItem("UpcomimgName", this.fullNameOne);
        // localStorage.clear('test Name')
        // localStorage.setItem("test Name", this.futureTourTwo[0].full_name);
        // localStorage.getItem("test Name");
        // localStorage.setItem("Tournament Name", this.futureTour[0].full_name);
        // localStorage.setItem("Tournament Name", this.futureTourTwo[0].full_name);
        // localStorage.setItem("Tournament Name", this.futureTourThree[0].full_name);
    // this.removeDups();
  }
};
</script>

<style scoped>
.noPadRight {
  padding-right: 0;
}
.noPadLeft {
  /* padding-left: ; */
}
.reportsSection {
  position: absolute;
  /* top: 35px; */
  bottom: 0px;
  width: 100%;
}
svg.icon.svg-inline--fa.fa-angle-right.fa-w-8 {
  margin-left: 3px;
  margin-top: 4px;
}
.nav-link {
  color: #003d52 !important;
  width: 100%;
  display: inline-flex;
  padding: 0;
  padding-bottom: 1rem;
}
li.nav-item.btnColor {
  font-size: 14px;
  font-weight: 600;
}
.reportTag {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.LinkNames {
  color: #003C52;
  font-size: 14px;
  font-weight: 600;
}
.noHover {
  text-decoration: none;
}
.sponLogo {
  display: block;
  max-height: 90px;
  /* width: 100%; */
  margin: auto;
  text-align: center;
  padding-bottom: 10px;
  /* max-width: 125px; */
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}
.date {
  color: black;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Gantari', sans-serif!important;
  text-align: center;
  margin-bottom: 0;
}
.name {
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 0px;
  /* white-space: nowrap; */
  /* width: 200px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  text-align: center;
  line-height: 2;
}
.course {
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
  color: #000;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
::v-deep .card{
  /* height: 250px; */
    /* height: 232px; */
    height: 290px;
}
::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}
li.nav-item.reportButton {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 1200px) { 
  .reportsSection {
      position: absolute;
      /* top: 35px; */
      bottom: 0px;
      width: 100%;
      margin: auto;
  }
}
@media only screen and (max-width: 1199px) { 
  .noPadRight.smallnotpadLeft.col-lg-3 {
    padding-left: 0;
    padding-right: 15px;
  }
  .noPadLeft.smallnotpadLeft.col-lg-3 {
    padding-left: 0;
  }
  .reportsSection {
    /* display: none; */
  }
  ::v-deep .noPadRight.col-lg-3 {
      flex: 0 0 50%;
      max-width: 50%;
  }
  ::v-deep .noPadLeft.col-lg-3 {
      flex: 0 0 50%;
      max-width: 50%;
  }
  .nav-link {
    color: black !important;
    width: 100%;
    display: block;
    text-align: center;
    margin: auto;
  }
  li.nav-item.btnColor {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    display: inline;
  }
  .card {
    height: 315px;
  }
}
@media only screen and (max-width: 768px) {
  .card {
    /* height: 250px; */
    width: 100%;
  }
  .upEvents {
    margin-bottom: 20px!important;
  }
  ::v-deep li.nav-item.reportButton > .nav-link {
    padding: .5rem 0rem!important;
  }
  li.nav-item.reportButton {
    position: absolute;
    bottom: 0;
    left: 15px;
  }
}
@media only screen and (max-width: 767px) { 
  .reportsSection {
    width: 100%;
  }
  .noPadRight {
    padding-right: 15px;
  }
  .noPadLeft {
    padding-left: 15px;
  }
  .noPadRight.smallnotpadLeft.col-lg-3 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .noPadLeft.smallnotpadLeft.col-lg-3 {
    padding-left: 15px;
    padding-right: 15px;
  }
  li.nav-item.btnColor {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .col-sm-4 {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .noPadRight.col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
  .noPadLeft.col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
  }
}
@media only screen and (max-width: 500) {
  li.nav-item.reportButton {
    position: absolute;
    bottom: 0;
    left: 0px!important;
  }
}
</style>
